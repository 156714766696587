<template>
	<div class="grid">
		<div class="col-9">
			<div class="card">
                <h3>Mensaje</h3>
                <Textarea v-model="sHtml"  cols="100" rows="10"/>
                <Button label="Guardar" @click="existActiveCampaign" class="p-button-rounded mr-2 mb-2"></Button>
                <Button label="Regresar" @click="returnMain" class="p-button-rounded mr-2 mb-2"> </Button>
            </div>
        </div>
        <div class="col-3">
              
            <div class="card">
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="price">Asunto</label>
                        <InputText id="name" type="text" v-model="sSubject"/>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="price">Lista de Clientes</label>
                        <Dropdown v-model="idSegment" :options="segments" optionValue="idOption" optionLabel="sOption" placeholder="Elige un Segmento" ></Dropdown>
                    </div>
                    <div v-if="idSegment==3" class="field col">
                        <Textarea v-model="sMailList" cols="25" rows="10"  />
                    </div>
                </div>
            </div>            
        </div>
	</div>

</template>
<script>
/* eslint-disable */
export default {
	data() {
		return {
            segments: null, 
            sHtml: '',
            bCorrect: false,
            idSegment: 0, 
            sMailList: '',
            sSubject: ''
		}
	},
	created() {
	},
	mounted() {
		this.isTokenExpired()
        this.getSegments()
        
	},
	methods: {
        async existActiveCampaign(){
            let band = false;
            await axios.get(apiURL + 'AdvertisingCampaign/existActiveCampaign?idSegmentType=' + this.idSegment, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                if(response.data.bCorrect){
                    band = true
                }
            } )
            .catch( (error)  => {
                console.log("error")
            } )

            let bandConfirm = true;
            if(band){
                await Swal.fire({
                    title: 'Existe una campaña de publicidad para el mismo segmento de clientes sin terminar. Si Agrega otra campaña la anterior será inhabilitada',
                    text: "¿Desea Crear una nueva campaña de publicidad?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, crearla',
                    cancelButtonText: 'Cancelar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        bandConfirm = true
                    }
                    else{
                        bandConfirm = false
                        this.showSuccess('Informe', 'No se creo la nueva campaña')
                    }
                })
            }

            if(bandConfirm){
                this.saveCampaign()
            }

        },
		async saveCampaign(){
            if(this.idSegment == 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe elegir un segemento',
                    footer: ''
                })
                return
            }

            if(this.sSubject == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El asunto es requerido',
                    footer: ''
                })
                return
            }

            if(this.idSegment == 3 && this.sMailList == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe especificar al menos un correo',
                    footer: ''
                })
                return
            }

            if(this.sHtml == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El correo no puede ir vacío',
                    footer: ''
                })
                return
            }

            let model = {
                idSegmentType: this.idSegment,
                sSubject: this.sSubject,
                sMessage: this.sHtml,
                sMailList: this.sMailList
            }

            this.openLoading('Guardando Datos...')
            await axios.post(apiURL + 'AdvertisingCampaign/addAdvertisingCampaign', model, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                Swal.close()
                if(response.data.bCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })						
                    return
                }
                else{
                    Swal.fire('Campaña guardada correctamente')
                    router.push('/advertisingCampaign')
                }
            } )
            .catch( (error)  => {
                Swal.close()
                console.log("error")
            } )
            
        },
        async getSegments(){
            await axios.get(apiURL + 'Customers/getSegments', { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                this.segments = response.data.options
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        returnMain(){
            router.push('/advertisingCampaign')
        }

	}
}
</script>

<style scoped lang="scss">

</style>
